import React from 'react'
import { L10nProvider } from '@logdoc/l10n';
import { ViewLogs } from '../view'

const View = () => {
    return (
        <L10nProvider>
            <ViewLogs />
        </L10nProvider>
    )
}

export default View;
